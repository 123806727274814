<template>
  <form
    id="microblading-info"
    action
    data-vv-scope="microblading-info-form"
    @submit.prevent="validateForm('microblading-info-form')"
  >
    <v-row>
      <v-col cols="12">
        <ServiceSelector />
      </v-col>
      <v-col>
        <v-radio-group v-model="customer.sub_type">
          <v-row>
            <v-col
              v-for="part in parts_list"
              :key="part.id"
              cols="12"
              sm="6"
              md="3"
              class="py-0"
              :style="
                $vuetify.breakpoint.xsOnly
                  ? 'display: flex; justify-content:center;cursor:default !important'
                  : 'center;cursor:default !important'
              "
            >
              <v-card
                @click="select(part)"
                v-bind:class="[
                  {
                    select: customer.sub_type === part.description,
                  },
                ]"
              >
                <v-radio
                  style="position: absolute; left: 5px; top: 10px"
                  :value="part.description"
                ></v-radio>
                <v-card-title class="ml-5" v-text="title(part)"> </v-card-title>
                <!--
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <p> Precio: {{part}} </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                -->
                <v-img :src="part.url" height="100"></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="2">
        <label class="text-no-wrap">{{ $t("n_customers") }}</label>
        <v-select
          :items="range()(1, 5)"
          outlined
          dense
          hide-details
          v-model="n_customers"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <label>{{ $t("appointments.description") }}</label>
        <v-textarea
          outlined
          v-model="customer.description"
          auto-grow
          rows="1"
          hide-details
          style="border-radius: 10px"
        ></v-textarea>
      </v-col>
    </v-row>
    <div class="pa-3 text-right">
      <v-btn
        color="primary"
        v-if="!$route.params.tattooer_id || $route.params.pre"
        @click="
          $route.params.pre && $route.params.tattooer_id
            ? $router.push({
                name: 'customer_inscription_studio_pre',
                params: {
                  token: $route.params.token,
                  back: true,
                },
              })
            : $emit('back')
        "
        style="height: 30px; width: 115px"
        outlined
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        style="height: 30px"
        elevation="0"
        class="ml-2"
      >
        {{ $t("next") }}
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "MicrobladingInfo",
  data() {
    return {
      parts_list: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.fetchBoxes();
      //this.fetchMarketplace();
    }, 1000);
  },
  components: {
    ServiceSelector: () => import("@/components/inscription/ServiceSelector"),
  },
  methods: {
    ...mapGetters("app", ["range"]),
    select(part) {
      console.log("TEST", part);
      this.customer.sub_type = part.description;
      this.$set(this.customer, "sub_type", part.description);
      this.customer.sub_type_id = part.id;
      this.$set(this.customer, "sub_type_id", part.id);
      //this.customer.body_part = part.description;
      //this.$set(this.customer, "sub_artist_type", part);
    },
    async validateForm(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.$emit("validated", this.studio);
        }
      });
    },
    ...mapActions("boxes", ["getBoxes"]),
    fetchBoxes() {
      if (this.$store.getters["auth/isLoggedIn"])
        this.getBoxes({
          pagination: this.options,
          filters: { ...this.filters, studio_id: this.customer.studio.id },
        }).then((boxes) => {
          this.boxes = boxes.data;
        });
    },
    ...mapActions("marketplace", ["getAllMarketplace"]),
    fetchMarketplace() {
      this.getAllMarketplace({ filters: { marketplace: "microblading" } }).then(
        (response) => {
          this.parts_list = response;
        }
      );
    },
    title(part) {
      let text = part.description;

      console.log(part);
      switch (part.marketplace_pay) {
        case "token_payment":
          text += ` - `;
          text += this.$n(part.paymentSignalPrice, "currency");
          break;
        case "price":
          text += ` - `;
          text += this.$n(part.price, "currency");
          break;
      }
      return text;
    },
    ...mapActions("subservices", ["getSubServices"]),
    fetchSubservice() {
      this.getSubServices({
        filters: { studio_id: this.customer.studio.id, marketplace: true },
        pagination: { itemPerPage: -1 },
        service_id: 3,
      }).then((response) => {
        this.parts_list = response.data;
      });
    },
  },
  computed: {
    ...mapState("inscriptions", ["tattooerState", "customerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },

    n_customers: {
      get() {
        return this.customer.extra_customers.length + 1;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_EXTRA_CUSTOMERS", value - 1);
      },
    },
    boxes_obligatoris() {
      if (this.$store.getters["auth/isLoggedIn"]) {
        let s = this.customer.studio.user.setting.find(
          (x) => x.key === "box_active"
        );
        if (s && s.value === "1") {
          let s2 = this.customer.studio.user.setting.find(
            (x) => x.key === "boxes_obligatoris"
          );
          if (s2) {
            return s2.value === "1";
          }
        }
      }
      return false;
    },
    marketplace() {
      if (this.customer.tattooer && this.customer.tattooer.id === -1)
        return false;
      let s = this.customer.studio.user.setting.find(
        (x) => x.key === "marketplace_active_microblading"
      );
      if (s) {
        return s.value === "1";
      }
      return false;
    },
  },
};
</script>